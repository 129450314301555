<template>
  <b-container fluid>
      <b-row>
          <b-col md="12">
              <iq-card>
                  <template v-slot:headerTitle>
                      <h4 class="card-title">{{$t('elearning_tim.registered_organization')}}</h4>
                  </template>
                  <template v-slot:headerAction>
                    <router-link to="trainee-selection" :class="'btn btn_add_new mr-2'">{{ $t('elearning_tim.trainee_selection') }} {{ $t('globalTrans.list') }}</router-link>
                  </template>
                  <template v-slot:body>
                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                      <b-form id="form" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                        <b-overlay :show="load">
                          <b-row>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="fiscal_year_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                                  </template>
                                  <!-- <b-form-select
                                      plain
                                      v-model="formData.fiscal_year_id"
                                      :options="fiscalYearList"
                                      id="fiscal_year_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select> -->
                                  <v-select
                                      id="fiscal_year_id"
                                      v-model="formData.fiscal_year_id"
                                      :reduce="op => op.value"
                                      :options="fiscalYearList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1">
                              <ValidationProvider name="Organization" vid="nominated_org_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="nominated_org_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('externalUserIrrigation.organization')}} <span class="text-danger">*</span>
                                  </template>
                                  <!-- <b-form-select
                                      plain
                                      v-model="formData.nominated_org_id"
                                      :options="orgList"
                                      id="nominated_org_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select> -->
                                  <v-select
                                      id="nominated_org_id"
                                      v-model="formData.nominated_org_id"
                                      :reduce="op => op.value"
                                      :options="orgList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="training_type_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                                  </template>
                                  <!-- <b-form-select
                                    plain
                                    v-model="formData.training_type_id"
                                    :options="trainingTypeList"
                                    id="training_type_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select> -->
                                  <v-select
                                      id="training_type_id"
                                      v-model="formData.training_type_id"
                                      :reduce="op => op.value"
                                      :options="trainingTypeList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Training Category" vid="training_category_id" rules="required|min_value:1">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="training_category_id"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                                  </template>
                                  <!-- <b-form-select
                                    plain
                                    v-model="formData.training_category_id"
                                    :options="trainingCategoryList"
                                    id="training_category_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select> -->
                                  <v-select
                                      id="training_category_id"
                                      v-model="formData.training_category_id"
                                      :reduce="op => op.value"
                                      :options="trainingCategoryList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="training_title_id"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                                  </template>
                                  <!-- <b-form-select
                                    plain
                                    v-model="formData.training_title_id"
                                    :options="trainingTitleList"
                                    id="training_title_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select> -->
                                  <v-select
                                      id="training_title_id"
                                      v-model="formData.training_title_id"
                                      :reduce="op => op.value"
                                      :options="trainingTitleList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Batch" vid="batch_no" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="batch_no"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_iabm.batch_no')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.batch_no"
                                      :options="batchList"
                                      id="batch_no"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                      <b-form-select-option value=0>{{ $t( circularLoading ? 'Loading....' : 'globalTrans.select') }}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="circular_memo_no"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.circular_memo_no"
                                      :options="circularList"
                                      id="circular_memo_no"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                      <b-form-select-option value=''>{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                              <ValidationProvider name="Organization Type" vid="profession_type" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="profession_type"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('elearning_config.organization_type')}}
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.profession_type"
                                      :options="orgTypeList"
                                      id="profession_type"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="formData.profession_type === 1">
                              <ValidationProvider name="Trainee Organization" vid="org_id">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="org_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('elearning_tim.trainee_organization')}}
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.org_id"
                                      :options="orgList"
                                      id="org_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                          </b-row>
                          <b-row class="mt-3 mb-3">
                            <b-col>
                              <span style="background-color: green;" class="text-light p-1">{{ $t('elearning_tim.total_registered_participant') }}</span> : {{ $n(countRegisteredParticipant) }}
                            </b-col>
                          </b-row>
                          <b-overlay :show="mainloading">
                            <b-row>
                              <b-col md="12" class="table-responsive">
                                <fieldset class="p-0 w-100">
                                  <legend class="px-2 w-50 shadow-sm">{{$t('elearning_tpm.registration_list')}}</legend>
                                  <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                                    <b-row class="mb-3">
                                      <b-col lg="6" class="my-1">
                                        <b-form-group
                                          :label="$t('elearning_tim.filter')"
                                          label-for="filter-input"
                                          label-cols-sm="3"
                                          label-align-sm="right"
                                          label-size="sm"
                                          class="mb-0"
                                        >
                                          <b-input-group size="sm">
                                            <b-form-input
                                              id="filter-input"
                                              v-model="filter"
                                              type="search"
                                              :placeholder="$t('elearning_tim.type_to_search')"
                                            ></b-form-input>

                                            <b-input-group-append>
                                              <b-button type="button" variant="primary" :disabled="!filter" @click="filter = ''">{{$t('elearning_tim.clear')}}</b-button>
                                            </b-input-group-append>
                                          </b-input-group>
                                        </b-form-group>
                                      </b-col>
                                    </b-row>
                                    <b-col md="12" class="table-responsive">
                                      <b-table head-variant="light" :sort-by.sync="sortBy" class="tg" :filter="filter" :items="mylistData" :filter-included-fields="filterOn" :emptyText="$t('globalTrans.noDataFound')" show-empty outlined hover :fields="columns">
                                        <template v-slot:cell(serial)="data">
                                          {{ $n(data.item.serial + 1) }}
                                        </template>
                                        <template v-slot:cell(name)="data">
                                          {{ ($i18n.locale==='bn') ? data.item.name_bn : data.item.name }}
                                        </template>
                                        <template v-slot:cell(mobile)="data">
                                          {{ data.item.mobile }}
                                        </template>
                                        <template v-slot:cell(designation)="data">
                                          <span v-if="data.item.designation_id && data.item.not_here_designation == 0 && data.item.profession_type == 1">{{ ($i18n.locale==='bn') ? data.item.designation_name_bn : data.item.designation_name }}</span>
                                          <span v-else>{{ ($i18n.locale=='bn') ? data.item.designation_bn : data.item.designation_en}}</span>
                                        </template>
                                        <template v-slot:cell(select)="data">
                                          <b-form-checkbox
                                            :id="'checkbox-' + data.item.id"
                                            v-model="data.item.select"
                                            :name="'checkbox-' + data.item.id"
                                            value=1
                                            unchecked-value=0
                                          >
                                          </b-form-checkbox>
                                        </template>
                                      </b-table>
                                    </b-col>
                                  </slot>
                                </fieldset>
                              </b-col>
                            </b-row>
                          </b-overlay>
                          <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                              <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.save') }}</b-button>
                                &nbsp;
                                <router-link to="trainee-selection" class="mr-1 btn btn-danger">{{ $t('globalTrans.cancel') }}</router-link>
                            </div>
                          </div>
                        </b-overlay>
                      </b-form>
                    </ValidationObserver>
                  </template>
              </iq-card>
          </b-col>
      </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { nominatedStore, circularList, personalInfoList, countRegisteredParticipant } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'

export default {
  mixins: [ModalBaseMasterList],
  data () {
    return {
      search: {
        org_id: 0,
        mobile: ''
      },
      formData: {
        registration_for: 2,
        check_time: '',
        fiscal_year_id: 0,
        batch_no: 0,
        coordinator_id: 0,
        circular_memo_no: '',
        training_category_id: null,
        training_type_id: null,
        training_title_id: null,
        profession_type: 0,
        org_id: null,
        nominated_org_id: null,
        personalList: []
      },
      mylistData: [],
      applied: [],
      officeTypeList: [],
      officeList: [],
      trainingTypeList: [],
      trainingCategoryList: [],
      trainingTitleList: [],
      batchList: [],
      allBatchListData: [],
      load: false,
      mainloading: false,
      detailsErrors: [],
      circularList: [],
      circularLoading: false,
      circularErrorMsg: false,
      trainerProfileError: '',
      countRegisteredParticipant: 0,
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '15%' } },
        { labels: 'globalTrans.name', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '25%' } },
        { labels: 'globalTrans.mobile', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '20%' } },
        { labels: 'globalTrans.designation', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '25%' } },
        { labels: 'globalTrans.select', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '15%' } }
      ],
      filter: null,
      filterOn: [],
      myFilter: (option, text, search) => {
                const temp = search.toLowerCase()
                return option.text_en.toLowerCase().indexOf(temp) > -1 ||
                option.text_bn.toLowerCase().indexOf(temp) > -1
            }
    }
  },
  computed: {
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'name' },
          { key: 'mobile' },
          { key: 'designation' },
          { key: 'select' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'name' },
          { key: 'mobile' },
          { key: 'designation' },
          { key: 'select' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    currentLocale () {
      return this.$i18n.locale
    },
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    orgTypeList: function () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়/আওতাধীন সংস্থা' : 'MoA/Organization under MoA',
          text_en: 'MoA/Organization under MoA',
          text_bn: 'কৃষি মন্ত্রণালয়/আওতাধীন সংস্থা'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'অন্যান্য সরকারী সংস্থা' : 'Other Government Organization',
          text_en: 'Other Government Organization',
          text_bn: 'অন্যান্য সরকারী সংস্থা'
        },
        {
          value: 3,
          text: this.$i18n.locale === 'bn' ? 'বেসরকারি সংস্থা' : 'Non-Goverment Organization',
          text_en: 'Non-Goverment Organization',
          text_bn: 'বেসরকারি সংস্থা'
        }
      ]
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    allBatchList () {
      return [
        { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
        { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
        { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
        { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
        { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
        { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
        { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
        { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
        { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
        { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
      ]
    }
  },
  watch: {
    'formData.nominated_org_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.formData.training_type_id = null
        this.trainingTypeList = this.getTypeList(newValue)
      } else {
        this.trainingTypeList = []
      }
    },
    'formData.training_type_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.formData.training_category_id = null
        this.trainingCategoryList = this.getCategoryList(newValue)
      } else {
        this.trainingCategoryList = []
      }
    },
    'formData.training_category_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.formData.training_title_id = null
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      } else {
        this.trainingTitleList = []
      }
    },
    'formData.fiscal_year_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.formData.circular_memo_no = ''
        this.formData.batch_no = 0
        this.getCircularList()
      }
    },
    'formData.training_title_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.formData.circular_memo_no = ''
        this.formData.batch_no = 0
        this.getCircularList()
      }
    },
    'formData.org_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.loadData()
        // this.countparticipant()
      }
    },
    'formData.profession_type': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        // if (newVal === 2 || newVal === 3) {
          this.formData.org_id = 0
          this.formData.personalList = []
          this.loadData()
        // }
      }
    },
    'formData.circular_memo_no': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const allBatchListData = this.allBatchListData.find(allBatchListData => String(allBatchListData.circular_memo_no) === String(newVal))
        if (typeof allBatchListData !== 'undefined') {
          this.formData.batch_no = allBatchListData.batch_no
        }
        this.loadData()
        // this.countparticipant()
      }
    },
    'formData.batch_no': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const allBatchListData = this.allBatchListData.find(allBatchListData => parseInt(allBatchListData.batch_no) === parseInt(newVal))
        if (typeof allBatchListData !== 'undefined') {
          this.formData.circular_memo_no = allBatchListData.circular_memo_no
        }
        this.loadData()
      }
    }
  },
  created () {
    this.formData = Object.assign({}, this.formData, {
      fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
    })
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.formData.nominated_org_id = this.$store.state.Auth.authUser.org_id
      if (this.$store.state.Auth.authUser.is_org_admin !== 1 && parseInt(this.$store.state.Auth.authUser.org_id) === 12) {
        this.formData.coordinator_id = this.$store.state.Auth.authUser.user_id
      }
    }
    this.loadData()
   // this.countparticipant()
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  methods: {
    getTypeList (orgId) {
      if (this.formData.nominated_org_id) {
        const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
        if (orgId) {
          return type.filter(item => item.org_id === parseInt(orgId))
        }
        return type
      }
    },
    batchInfo () {
      const batchData = this.allBatchListData.filter(allBatchList => parseInt(allBatchList.training_title_id) === parseInt(this.formData.training_title_id))
      const batchList = []
      batchData.map((obj, index) => {
        if (obj.batch_no) {
          const isThere = this.allBatchList.find(allBatchList => parseInt(allBatchList.value) === parseInt(obj.batch_no))
          if (typeof isThere !== 'undefined') {
            batchList.push(isThere)
          }
        }
      })
      this.batchList = batchList
      if (this.batchList.length === 1) {
        this.formData.batch_no = 1
      }
    },
    circluarList () {
      const listObject = this.allBatchListData.filter(allBatchListData => parseInt(allBatchListData.training_title_id) === parseInt(this.formData.training_title_id))
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
        } else {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
        }
      })
      this.circularList = tmpList
    },
    async getCircularList () {
      if (this.formData.fiscal_year_id && this.formData.training_title_id) {
        this.circularLoading = true
        const serchData = {
          fiscal_year_id: this.formData.fiscal_year_id,
          training_type_id: this.formData.training_type_id,
          training_category_id: this.formData.training_category_id,
          training_title_id: this.formData.training_title_id,
          coordinator_id: this.formData.coordinator_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularList + '/' + 'iab_circular_publications', serchData)
        if (!result.success) {
          this.allBatchListData = []
        } else {
          this.allBatchListData = result.batchList
          this.batchInfo()
          this.circluarList()
        }
        this.circularLoading = false
      }
    },
    async loadData () {
      // if (this.formData.batch_no && this.formData.nominated_org_id) {
        this.mainloading = true
        const data = this.formData
        data.personalList = []
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, personalInfoList, data)
        if (result.success) {
          this.countRegisteredParticipant = result.total
          this.applied = result.applied
          const datas = result.data.map((item, index) => {
            const desigData = {}
            const desigObj = this.$store.state.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
            let appliedObj = {}
            if (this.formData.registration_for === 1) {
              appliedObj = this.applied.find(applied => applied.personal_info_id === parseInt(item.personal_info_id) && applied.sort_status === 3)
            } else {
              appliedObj = this.applied.find(applied => applied.personal_info_id === parseInt(item.personal_info_id) && applied.sort_status === 2)
            }
            if (typeof appliedObj !== 'undefined') {
              desigData.select = '1'
              desigData.training_application_id = appliedObj.id
              desigData.accommodation_type = 1
            } else {
              desigData.select = 0
              desigData.training_application_id = 0
              desigData.accommodation_type = 1
            }
            if (typeof desigObj !== 'undefined') {
              desigData.designation_name = desigObj.text_en
              desigData.designation_name_bn = desigObj.text_bn
            } else {
              desigData.designation_name = ''
              desigData.designation_name_bn = ''
            }
            return Object.assign({}, item, { serial: index }, desigData)
          })
          this.formData.personalList = datas
          this.mylistData = datas
        } else {
          this.formData.personalList = []
          this.mylistData = []
          this.countRegisteredParticipant = 0
        }
        this.mainloading = false
      // }
    },
    getOfficeTypeList (orgId) {
      if (this.formData.nominated_org_id) {
        const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
        if (orgId) {
          return OfficeTypeList.filter(item => item.org_id === orgId)
        }
        return OfficeTypeList
      }
    },
    getOfficeList (officeTypeId) {
      if (this.formData.office_type_id) {
        const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
        if (officeTypeId) {
          return officeList.filter(item => item.office_type_id === officeTypeId)
        }
        return officeList
      }
    },
    getCategoryList (typeId) {
      if (this.formData.training_type_id) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      }
    },
    getTrainingTitleList (categoryId) {
      if (this.formData.training_category_id) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      }
    },
    async createData () {
      this.load = true
      let result = null
      result = await RestApi.postData(trainingElearningServiceBaseUrl, nominatedStore, this.formData)
      if (result.success) {
        this.formData = {
        registration_for: 2,
        check_time: '',
        fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId,
        batch_no: 0,
        circular_memo_no: '',
        training_category_id: 0,
        training_type_id: 0,
        training_title_id: 0,
        profession_type: 1,
        org_id: 0,
        personalList: []
      }
      if (this.$store.state.Auth.activeRoleId !== 1) {
        this.formData.nominated_org_id = this.$store.state.Auth.authUser.org_id
      } else {
        this.formData.nominated_org_id = 0
      }
        // this.$socket.emit('send-notification', result.notification)
        this.$toast.success({
          title: 'Success',
          message: this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$refs.form.reset()
        // this.$router.push('/training-e-learning-service/tim/trainee-selection')
      }
      this.load = false
    },
  async countparticipant () {
      // if (this.formData.batch_no && this.formData.profession_type) {
      this.mainloading = true
      const data = this.formData
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, countRegisteredParticipant, data)
      if (result.success) {
        this.countRegisteredParticipant = result.countRegisteredParticipant
      } else {
        this.countRegisteredParticipant = ''
      }
      this.mainloading = false
     }
    // }
  },
  beforeDestroy () {
    clearInterval(this.inputTimer)
  }
  // onFiltered(filteredItems) {
  //   // Trigger pagination to update the number of buttons/pages due to filtering
  //   this.totalRows = filteredItems.length
  // }
}
</script>
